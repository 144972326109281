import styled from 'styled-components'

export const CardProductContainer = styled.div`
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    .product-card-item {
        flex-grow: 1;
        flex-basis: 25%;
        max-width: 25%;
        margin: 10px 5px;

        min-width: 250px;
    }
`

export const ProductCard = styled.div`
    height: 260px;
    width: 100%;
    min-width: 225px;
    max-width: 90%;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    background-image: url(${(props) => props.backgroundImage});
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    text-align: center;

    margin-top: 5px;
    padding: 15px;

    .content-product-card {
        position: absolute;
        right: 0;
        bottom: 0;
        left: 0;
        height: 45%;

        display: flex;
        flex-direction: column;
        justify-content: center;

        padding: 1.1rem 1.2rem;
        background-color: rgba(57, 55, 55, 0.8);

        border-bottom: 4px solid #d6373b;
        color: #f1f1f1;
        -webkit-transition: all 0.2s ease-out 0s;
        transition: all 0.2s ease-out 0s;
        cursor: pointer;

        h2 {
            font-size: 18px;
            font-weight: 900;
            margin-bottom: 5px;
            text-transform: uppercase;
        }
    }
    :hover,
    :focus {
        .content-product-card {
            padding: 1.5rem 1.875rem;
            border-bottom: 5px solid #d22327;
        }
    }
`
