import React from 'react'

import Layout from '../../layout'
import SEO from '../../utils/seo'

import ProductsContent from '../../components/Modules/Solutions/ProductsContent'

const CategoriesPage = ({ pageContext }) => {
    const { title, description, background, products } = pageContext
    return (
        <Layout>
            <SEO title={title} />

            <ProductsContent
                title={title}
                description={description}
                heroBackgroundImage={background}
                productsData={products}
            />
        </Layout>
    )
}

export default CategoriesPage
