import { Link } from 'gatsby'
import React from 'react'

import Divider from '../../../Divider'

import { formatPathURL } from '../../../../utils/sanitization'

import { CardProductContainer, ProductCard } from './styles'
import HeroContainer from '../../../Elements/HeroContainer'
import { sortByAlphabetic } from '../../../../utils/array.utils'

const ProductContent = (props) => {
    const { heroBackgroundImage, title, description, productsData } = props

    return (
        <>
            <HeroContainer
                backgroundImage={heroBackgroundImage}
                title={title}
                description={description}
            />

            <Divider size={8} orientation="horizontal" />

            <CardProductContainer>
                {productsData
                    ?.sort((productA, productB) =>
                        sortByAlphabetic('asc', productA?.name, productB?.name),
                    )
                    ?.map((product, index) => (
                        <Link
                            className="product-card-item"
                            key={index}
                            to={`/produto/${formatPathURL(product?.name)}`}
                        >
                            <ProductCard backgroundImage={product?.image?.publicURL}>
                                <div className="content-product-card">
                                    <h2>{product.name}</h2>
                                </div>
                            </ProductCard>
                        </Link>
                    ))}
            </CardProductContainer>

            <Divider size={8} orientation="horizontal" />
        </>
    )
}

export default ProductContent
